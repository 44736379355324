<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/"
                       class="d-flex align-center">
            <v-img :src="appLogo"
                   max-height="30px"
                   max-width="30px"
                   alt="logo"
                   contain
                   class="me-3 "></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Reset Password
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text v-if="errorMessage">
          <v-alert text
                   color="error">
            <small class="d-block mb-1">
              {{ errorMessage }}
            </small>
          </v-alert>
        </v-card-text>

        <!-- login form -->
        <v-card-text>

          <v-form ref="resetForm">
            <v-text-field v-model="password"
                          outlined
                          :type="isPasswordVisible ? 'text' : 'password'"
                          label="New Password"
                          placeholder="············"
                          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                          :rules="[validators.required, validators.minLengthValidator(password, 8)]"
                          class="mb-3"
                          @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

            <v-text-field v-model="confirmPassword"
                          outlined
                          :type="isConfirmPasswordVisible ? 'text' : 'password'"
                          label="Confirm Password"
                          placeholder="············"
                          :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                          :rules="[validators.required, validators.confirmedValidator(confirmPassword,password)]"
                          @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"></v-text-field>

            <v-btn block
                   color="primary"
                   class="mt-4"
                   @click="SetNewPassword">
              Set New Password
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- back to login -->
        <v-card-actions class="d-flex justify-center align-center">
          <router-link :to="{name:'auth-login'}"
                       class="d-flex align-center text-sm">
            <v-icon size="24"
                    color="primary">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back to login</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'
import { useRouter } from '@core/utils'
import store from '@/store'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const password = ref('')
    const confirmPassword = ref('')
    const resetForm = ref(null)
    const errorMessage = ref(null)

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    const { route, router } = useRouter();
    const code = route.value.params.code;

    const SetNewPassword = () => {

      if (resetForm.value.validate()) {
        errorMessage.value = null;
        vm.$http
          .post(vm.$store.state.apiUrl + '/auth/reset-password/' + code, {
            code: code,
            password: password.value
          })
          .then(response => {

            if (response.data) {

              store.commit('app/SNACKBAR_ADD', { color: "success", message: "Password Updated." });
              router.push({ name: 'auth-login' });

            } else {
              errorMessage.value = "Something has gone wrong, your reset link may have expired."
            }
          })
          .catch(error => {
            errorMessage.value = "Something has gone wrong, please try again later."
          });
      }
    }

    return {
      errorMessage,
      resetForm,
      validators,
      isPasswordVisible,
      isConfirmPasswordVisible,
      password,
      confirmPassword,
      SetNewPassword,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
